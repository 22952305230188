import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/main.scss";
import App from "./App";
import ThemeWrapper from "./theme";
import { AuthContextProvider } from "./hooks/useAuth";
import { ThemeContextProvider } from "./hooks/useTheme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeWrapper>
      <ThemeContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ThemeContextProvider>
    </ThemeWrapper>
  </React.StrictMode>
);
