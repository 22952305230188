import {
    Alert,
    Badge,
    Chip,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditUserDialog from "./EditUserDialog";
import axios from "axios";

const UserTable = ({
    users,
    updateUserInStore,
    departments
}) => {
    const defaultSnackbarProps = { open: false, severity: "success", text: "" };

    const [selectedUser, setSelectedUser] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [snackBarProps, setSnackBarProps] = useState(defaultSnackbarProps);

    const handleUserSelect = (user) => {
        setShowEditDialog(true);
        setSelectedUser(user);
    }

    const handleSave = (user) => {
        setIsSaving(true);
        axios.post(`Users/UpdateUser/${user.id}`, user)
            .then(res => {
                updateUserInStore(res.data);
                setShowEditDialog(false);
                setSnackBarProps({ open: true, severity: "success", text: "Successfully updated the user" });
            })
            .catch(e => {
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Error updating user",
                });
            })
            .finally(() => {
                setIsSaving(false);
            })
    }

    return (
        <TableContainer component={Paper} className="users-table-container">
            {showEditDialog && (
                <EditUserDialog
                    open={showEditDialog}
                    handleClose={() => setShowEditDialog(false)}
                    isSaving={isSaving}
                    handleSave={handleSave}
                    originalUser={selectedUser}
                    setSnackBarProps={setSnackBarProps}
                />
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={snackBarProps.open}
                autoHideDuration={5000}
                onClose={() => setSnackBarProps(defaultSnackbarProps)}
            >
                <Alert severity={snackBarProps.severity}>{snackBarProps.text}</Alert>
            </Snackbar>
            <Table className="users-table" size="small" stickyHeader>
                <TableHead className="user-table-header">
                    <TableRow>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Email</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell><b>Phone</b></TableCell>
                        <TableCell><b>Department</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(u => {
                        return (
                            <TableRow className="users-table-row" key={`${u.id}-user-row`}
                                onClick={() => handleUserSelect(u)}
                            >
                                <TableCell>{`${u.firstName} ${u.lastName}`}</TableCell>
                                <TableCell>{`${u.email}`}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={`${u.isArchived ? "INACTIVE" : "ACTIVE"}`}
                                        className={`${u.isArchived == true ? "red-chip" : "green-chip"}`}
                                        variant="filled"
                                    />
                                </TableCell>
                                <TableCell>{`${u.phoneNumber ? u.phoneNumber : "-"}`}</TableCell>
                                <TableCell>{`${u.departmentId ? departments.find(d => d.id === u.departmentId)?.name : "-"}`}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UserTable;