import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signOut, signInWithPopup } from "firebase/auth";
import { auth } from "../config/firebase";
import axios from "axios";

export const AuthContext = createContext({
  user: null,
  firebaseUser: {},
});

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [user, setUser] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState({});

  const loginWithProvider = (provider) => {
    return signInWithPopup(auth, provider);
  };

  const createCustomToken = (loginRequest) => {
    return axios.post("auth/login", loginRequest, {
      withCredentials: true,
    });
  };

  const createAuthenticatedUser = async (firebaseCurrentUser) => {
    if (firebaseCurrentUser?.uid) {
      const res = await axios.post("auth/CreateAuthenticatedUser", {
        firebaseUid: firebaseCurrentUser?.uid,
        firstName: firebaseCurrentUser?.displayName.substring(
          0,
          firebaseCurrentUser?.displayName?.indexOf(" ")
        ),
        lastName: firebaseCurrentUser?.displayName
          .substring(firebaseCurrentUser?.displayName?.indexOf(" "))
          ?.trim(),
        role: "Editor",
        bio: "<p></p>",
        signature: "<p></p>",
        isArchived: false,
        photoUrl: "https://via.placeholder.com/600x600",
        email: firebaseCurrentUser.email,
      });
      if(res.data){
        setAuthenticatedUser(res.data)
        return res.data;
      }
    }
    return null;
  };

  const setAuthenticatedUser = (loadedUser) => {
    setUser(loadedUser);
    setIsAuthenticated(true);
  };

  const handleUnauthorizedUser = async (firebaseCurrentUser) => {
    try {
      const idToken = await firebaseCurrentUser.getIdToken(true);
      await createCustomToken({
        idToken,
        email: firebaseCurrentUser.email,
      });
      axios
        .get("auth/getAuthenticatedUser")
        .then(({ data }) => {
          setAuthenticatedUser(data)
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error(error);
    }
  };

  const getAuthenticatedUser = (firebaseCurrentUser) => {
    axios
      .get("auth/getAuthenticatedUser")
      .then(({ data }) => {
        setAuthenticatedUser(data)
      })
      .catch(async (err) => {
        if (
          err.response &&
          err.response.status === 401 &&
          firebaseCurrentUser?.uid
        ) {
          await handleUnauthorizedUser(firebaseCurrentUser);
        } else if (
          err.response &&
          err.response.status === 404 &&
          firebaseCurrentUser?.uid
        ) {
          await createAuthenticatedUser(firebaseCurrentUser);
        } else {
          console.error(err);
        }
      });
  };

  const logout = () => {
    return signOut(auth);
  };

  const hasAdminAccess = () => {
    return user?.role === "Admin" || user?.role === "Super User";
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (currentuser) {
        setIsLoadingUser(true);
        new Promise(() => {
          setFirebaseUser(currentuser);
          getAuthenticatedUser(currentuser);
        })
          .then((response) => console.log(response))
          .catch((error) => console.error(error))
          .finally(() => setIsLoadingUser(false));
      } else {
        setFirebaseUser(currentuser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        firebaseUser,
        user,
        isAuthenticated,
        isLoadingUser,
        getAuthenticatedUser,
        logout,
        loginWithProvider,
        createCustomToken,
        setIsLoadingUser,
        hasAdminAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
