import React, { useEffect, useState } from "react";
// import Header from "./Header";
import { Header, SidePanelMenu } from "@camistrynz/thomspsonspencer-ui";
import "../styles/appLayout.scss";
import ThompsonSpencerInitialsNoFill from "../assets/images/ThompsonSpencerInitialsNoFill";
import TsLoader from "./TsLoader";
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [adminSection, setAdminSection] = useState(null);
  const { isLoadingUser, user, hasAdminAccess} = useAuth();
  const { mode, toggleMode } = useTheme();

  useEffect(() => {
    document.documentElement.className =
      mode === "dark" ? "dark-mode" : "light-mode";
  }, [mode]);

  useEffect(() => {
    if (location?.pathname === "/access-denied") {
      setShowLoader(false);
    } else if (isLoadingUser) {
      setShowLoader(isLoadingUser);
    }
  }, [isLoadingUser]);

  useEffect(() => {
    if (hasAdminAccess()) {
      setAdminSection({
        name: "Admin",
        handleOnClick: () => {},
        icon: null,
        innerItems: [
          {
            name: "Users",
            handleOnClick: () => {navigate("/Users")},
            icon: null,
            innerItems: null,
          },
          {
            name: "Modules",
            handleOnClick: () => {alert("Under Development")},
            icon: null,
            innerItems: null,
          },
        ],
      });
    }
  }, [hasAdminAccess]);

  return (
    <div className="app-layout-root">
      <Header
        systemName="ZEUS"
        handleNavMenuClick={() => !showSidePanel ? setShowSidePanel(true) : setShowSidePanel(false)}
        handleTitleClick={() => navigate("/")}
        handleToggleMode={toggleMode}
        mode={mode}
      />
      {showSidePanel && user && <SidePanelMenu isOpen={showSidePanel} handleClose={() => setShowSidePanel(false)} firstName={user?.firstName} adminSection={adminSection}/>}
      {showLoader && <TsLoader setShowLoader={setShowLoader} />}
      {children}
      <div className="app-layout-logo-container">
        <Typography variant="caption" style={{opacity: 0}}>{`env: ${process.env.REACT_APP_ADMIN_PANEL?.toLowerCase()}`}</Typography>
        <ThompsonSpencerInitialsNoFill />
      </div>
    </div>
  );
};

export default AppLayout;
