import React from "react";

const Arrow = () => {
  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4957 21.3907H71.2771V63.1721"
        stroke="#1A1A1A"
        strokeWidth="2"
      />
      <path
        d="M71.0917 21.6268L21.4161 71.3024"
        stroke="#1A1A1A"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Arrow;
