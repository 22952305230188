import React, { useEffect, useState } from "react";
//-----
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@mui/material";
import axios from "axios";
//---FilePond---
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType
);

const EditUserDialog = ({
    open,
    handleClose,
    originalUser,
    handleSave,
    isSaving,
    setSnackBarProps
}) => {
    const RoleOptions = ["Editor", "Admin", "Super User"];
    const imageFileTypes = ["jpg", "jpeg", "png", "svg"];

    const [user, setUser] = useState({ ...originalUser });
    const [departments, setDepartments] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadingImage, setUploadingImage] = useState(false);

    const handleUserEdited = (property, newValue) => {
        setUser({
            ...user,
            [property]: newValue
        })
    }

    const getDepartments = () => {
        axios.get(`Departments/GetDepartments`)
            .then(res => {
                setDepartments(res.data);
            }).catch(e => console.log(e))
    }

    const uploadUserImageToCloud = () => {
        setUploadingImage(true);
        var newUser = { ...user };
        let formData = new FormData();
        formData.append("file", selectedFiles[0].file);
        axios.post(
            "Users/UploadUserImageToCloud",
            formData,
            {
                headers: { "content-type": "multipart/form-data" },
            }
        ).then(res => {
            newUser.photoUrl = res.data;
            handleSave(newUser);
        }).catch(e => {
            console.log(e);
            setSnackBarProps({ open: true, severity: "warning", text: "Failed to upload the image" });
        }).finally(() => {
            setUploadingImage(false);
        })
    };

    const generateTextField = (label, type, property, value) => {
        return (
            <TextField
                id={`${label}-text-field`}
                label={label}
                type={type}
                value={value}
                onChange={(e) => {
                    handleUserEdited(property, e.target.value);
                }}
                variant="filled"
                fullWidth
                disabled={isSaving || uploadingImage}
            />
        )
    }

    const saveNewUserInfo = () => {
        if (selectedFiles.length > 0) {
            uploadUserImageToCloud();
        } else {
            handleSave(user);
        }

    }

    useEffect(() => {
        setUser({ ...originalUser });
        getDepartments();
    }, [originalUser]);

    return (
        <Dialog
            id="edit-user-dialog"
            open={open}
            onClose={(e, reason) => {
                if (reason != "backdropClick" && reason != "escapeKeyDown") {
                    handleClose();
                }
            }}
        >
            <DialogTitle className='home-dashboard-heading' variant="h4">Edit User</DialogTitle>
            <DialogContent>
                <div className="edit-user-body-container">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            {generateTextField("Title", "text", "title", user.title)}
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            {generateTextField("First Name", "text", "firstName", user.firstName)}
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            {generateTextField("Last Name", "text", "lastName", user.lastName)}
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <TextField
                                id={`Email-text-field`}
                                label={"Email"}
                                type={"Text"}
                                value={user.email}
                                variant="filled"
                                fullWidth
                                helperText={"Email cannot be edited"}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            {generateTextField("Phone Number", "text", "phoneNumber", user.phoneNumber)}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Autocomplete
                                value={departments.find(d => d.id === user.departmentId) || null}
                                options={departments}
                                onChange={(e, newValue) => { 
                                    if(newValue){
                                        handleUserEdited("departmentId", newValue.id);
                                    } else {
                                        handleUserEdited("departmentId", null);
                                    }
                                }}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Department"
                                        size="small"
                                        fullWidth={true}
                                        variant="filled"
                                    />
                                )}
                                disabled={isSaving || uploadingImage}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Autocomplete
                                value={user.role}
                                options={RoleOptions}
                                onChange={(e, newValue) => { handleUserEdited("role", newValue) }}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Role"
                                        size="small"
                                        fullWidth={true}
                                        variant="filled"
                                    />
                                )}
                                disabled={isSaving || uploadingImage}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <div className="user-image-container">
                                <img src={user.photoUrl}
                                    width={"150px"}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <FilePond
                                files={selectedFiles}
                                onupdatefiles={(files) => {
                                    if (files[0] && imageFileTypes.includes(files[0].fileExtension)) {
                                        console.log("Pass");
                                        setSelectedFiles(files);
                                    } else {
                                        setSelectedFiles([]);
                                    }
                                }}
                                allowMultiple={false}
                                name="avatar"
                                labelIdle='<div class="roboto-regular">
                                    <div><span class="filepond--label-action">Click to upload</span> new user image or drag and drop<div>
                                    <div class="drag-drop-subline">SVG, PNG, JPG<div>
                                    </div>'
                                allowFileTypeValidation={true}
                                acceptedFileTypes={["image/*"]}
                                labelFileTypeNotAllowed="Must be an image"
                                disabled={isSaving || uploadingImage}
                                className={"custom-filepond"}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                {isSaving || uploadingImage ? (
                    <div style={{ padding: 10 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Button variant="contained" className="default-button" size="small"
                            onClick={handleClose}
                            disabled={isSaving}
                        >
                            Close
                        </Button>
                        <Button variant="contained" className="default-button" size="small"
                            onClick={() => saveNewUserInfo()}
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default EditUserDialog;