import { Backdrop, Fade, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../styles/tsLoader.scss";
import { useAuth } from "../hooks/useAuth";
import FontFaceObserver from "fontfaceobserver";
const TsLoader = ({ setShowLoader }) => {
  const sfProFont = new FontFaceObserver("SF-Pro");
  sfProFont.load().then(() => {
    setIsCustomFontLoaded(true);
  });
  const { user } = useAuth();
  const [isCustomFontLoaded, setIsCustomFontLoaded] = useState(false);
  const [fadeTransition, setfadeTransition] = useState(true);
  const [greetings, setGreetings] = useState("");

  useEffect(() => {
    if (greetings) {
      setTimeout(() => {
        setfadeTransition(false);
        setShowLoader(false);
      }, 2000);
    }
  }, [greetings]);

  useEffect(() => {
    if (user) {
      setGreetings(`Hi, ${user.firstName}!`);
    }
  }, [user]);

  return (
    <div className="thompson-spencer-loader-backdrop">
      <div className="thompson-spencer-loader-wrapper">
        {isCustomFontLoaded && (
          <>
            <div className="thompson-spencer-loader-text-container">
              {greetings && (
                <Fade in={fadeTransition} timeout={{ enter: 1000, exit: 500 }}>
                  <span>{greetings}</span>
                </Fade>
              )}
            </div>
          </>
        )}
        <div>
          <div className="thompson-spencer-loader-root">
            <div className="thompson-spencer-loader-logo">
              <img
                src="https://storage.googleapis.com/quotes-assets/logo-vector/TS-vector.svg"
                width={150}
                height={150}
              />
            </div>
            <div className="thompson-spencer-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TsLoader;
